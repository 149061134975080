<template>
  <v-dialog
    :value="value"
    justify="center"
    width="1160px"
    @click:outside="$emit('close')">
    <v-card style="position: relative; height: 700px;">
      <v-btn
        icon
        absolute
        top
        right
        @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="player-container">
        <v-progress-circular
          v-if="loading"
          :color="loaderColor"
          indeterminate
          size="64">
        </v-progress-circular>
        <video-player
          v-else
          class="vjs-custom-skin"
          :options="playerOptions">
        </video-player>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
// API
import Document from '@/axios/document-endpoints'
// mixins
import { displayAlert } from '@/mixins/alert'
// third-party
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
export default {
  data () {
    return {
      loading: true,
      playerOptions: {
        height: "600",
        width: "800",
        sources: [
          { type: "video/mp4", src: "" }
        ],
        autoplay: false,
        controls: true
      }
    }
  },
  name: 'VideoPlayer',
  components: { videoPlayer },
  mixins: [displayAlert],
  props: {
    value: Boolean,
    id: String,
    loaderColor: {
      type: String,
      required: false,
      default: 'grey lighten-1'
    }
  },
  async created () {
    this.getVideo(this.id)
  },
  methods: {
    async getVideo (id) {
      try {
        const res = await Document.getFile(id)
        if (res?.data?.url) {
          this.playerOptions.sources[0].src = res.data.url
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.player-container {
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 30px 0;
  height: 100%;
}
</style>